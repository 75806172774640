import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import MyBlock from "../components/block";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact us">
      <MyBlock title="Contact us" icon="at" bg="white">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-recaptcha="true"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="form-group">
            <label for="contact-name">Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              id="contact-name"
            />
          </div>
          <div className="form-group">
            <label for="contact-email">Email address</label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="contact-email"
              aria-describedby="emailHelp"
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label for="contact-object">Object</label>
            <input
              type="text"
              name="object"
              className="form-control"
              id="contact-object"
            />
          </div>
          <div className="form-group">
            <label for="contact-message">Message</label>
            <textarea
              type="text"
              name="message"
              className="form-control"
              id="contact-message"
              rows="10"
            ></textarea>
          </div>
          <ReCAPTCHA sitekey="6Lft6hofAAAAAGzM8VhgQIzDq6IsTTnSzWFL65sK" />
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </MyBlock>
    </Layout>
  );
};

export default ContactPage;
